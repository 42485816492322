module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",

    "landing__title": "Doll Yourself Up For Halloween!",
    "landing__subtitle": "This creepy thing turns people into evil dolls. Will you dare to upload your photo?",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Upload photo",

    "processing_text_1": "Ready to meet your inner Annabelle? 👀",
    "processing_text_2": "Such a cutie! Huh, we’re kidding. It’s creeeeeepy! 🤯",
    "processing_text_3": "Barbie? Meh, most probably, Chucky… 😝",
    "processing_text_4": "You never know how wicked your doll is… 👻",
    "processing_text_5": "Creepy things are the coolest, right? 🧟‍♂️",

    "collage__title": "Save and share",
    "collage__text_guest": "This is someone’s Evil Doll, but you could have your own!",
    "collage__tab_image": "Image",
    "collage__tab_video": "Video",
    "collage__tab_day": "Day of the Dead",
    "collage__tab_halloween": "Halloween",
    "collage__tab_doll": "Evil Doll",
    "collage__select_photo": "Try with your photo",
    "collage__download": "Download",
    "collage__refresh": "Refresh",

    "error__error": "Error",
    "modal_message": "Help it make new friends! Post the result on your social media using #EvilDoll, encourage your friends to follow your lead and… let's see whose doll is THE CREEPIEST! 👻",
    "modal_button": "Got it",
    "modal_title": "Your doll looks BOOtiful!",

    "internal_error": "An error occurred...",
  },
};

